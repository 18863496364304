.table-grid th {
  font-size: 12px;
}

.table-grid td {
  font-size: 12px;
}

/* generic loading */
.loadingBar{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;
}